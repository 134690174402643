<template>
  <div class="chart_all_wrap">
    <div class="chart_wrap">
      <GrowValue :myId="'chart1'+isDialogId"
                 :xValues="growNumX"
                 :dataMax="growNumMax"
                 :dataSelf="growNumSelf"
                 :maxYValue="maxYValue" />
    </div>
    <div class="chart_wrap">
      <RateValue :myId="'chart2'+isDialogId"
                 :xValues='rateDataX'
                 :dataAvg="rateDataAvg"
                 :dataUser="rateDataUser"
                 :dataMax="rateDataMax" />
    </div>
  </div>
</template>

<script setup>
import GrowValue from './charts/mychart1.vue'
import RateValue from './charts/myChart2.vue'


import { getScoreDetail, getGrowData } from '@/api/user.js'
import { ref, onMounted } from 'vue'

onMounted(() => {
  // this.getRate()
  // this.getGrowNum()
  getData()
})
let props = defineProps({
  isDialogId: {
    type: String,
    default: "00"
  }
})

let rateData = ref([])
let rateDataX = ref([])
let rateDataAvg = ref([])
let rateDataUser = ref([])
let rateDataMax = ref([])

let growNum = ref()
let growNumX = ref([])
let growNumSelf = ref([])
let growNumMax = ref([])
let maxYValue = ref(0)


const getRate = async () => {
  const { data } = await getScoreDetail()
  rateData.value = data.result
  for (let i = 0; i < rateData.value.length; i++) {
    rateDataX.value.push(rateData.value[i].subject_name)
    rateDataAvg.value.push(rateData.value[i].avg_score)
    rateDataUser.value.push(rateData.value[i].user_score)
    rateDataMax.value.push(rateData.value[i].max_score)
    // this.rateDataX.push(this.rateDate.subject_name)
  }
  // console.log(this.rateDataX, this.rateDataAvg, this.rateDataUser, this.rateDataMax)
}
const getGrowNum = async () => {
  const { data } = await getGrowData()
  growNum.value = data.data
  for (let i = 0; i < growNum.value.length; i++) {
    growNumX.value.push(growNum.value[i].subject_name)
    growNumMax.value.push(growNum.value[i].max_user_score)
    growNumSelf.value.push(growNum.value[i].user_score)
    // this.rateDataX.push(this.rateDate.subject_name)
  }
  maxYValue.value = Number(data.max_score)
  // console.log(data, this.growNumX, this.growNumMax, this.growNumSelf, 'a')
}
const getData = async () => {
  await getRate()
  getGrowNum()

}

</script>

<style lang="scss" scoped>
.chart_wrap {
  margin: 10rem 10rem 30rem;
}
.chart_all_wrap {
  height: 100%;
  overflow: auto;
}
</style>