<template>
  <div>
    <!-- <div class="module_name">
      成长值
    </div> -->
    <div class="mainChart">
      <div class="title">各科目当前成长值</div>
      <div :id="myId"
           ref="chartRef"
           style="width:95%;height:36vh;min-height:200px"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick, toRefs, onUnmounted } from 'vue'
import * as echarts from 'echarts'
let myChart
let chartRef = ref()
let props = defineProps(
  {
    myId: {
      type: String,
      default: ''
    },
    xValues: {
      type: Array,
      default: []
    },
    maxYValue: {
      type: Number,
      default: ''
    },
    dataMax: {
      type: Array,
      default: []
    },
    dataSelf: {
      type: Array,
      default: []
    }
  }
)
let xValues = toRefs(props).xValues
let maxYValue = toRefs(props).maxYValue
let dataMax = toRefs(props).dataMax
let dataSelf = toRefs(props).dataSelf
let topDataListPoints = computed(() => {

  let arr = []
  if (dataMax.value.length) {
    for (let i = 0; i < dataMax.value.length; i++) {
      arr.push({
        coord: [i, dataMax.value[i]]
      })
    }
  }

  return arr
}
)
watch(() => dataSelf, () => {
  nextTick(() => {
    drawLine()
  })

}, { deep: true })

const clear = () => {
  if (myChart) {
    myChart.dispose()
  }
}
const drawLine = () => {
  myChart = echarts.init(chartRef.value)
  myChart.setOption({
    xAxis: [
      {
        type: 'category',
        // data: ['语文', '数学', '英语', '历史', '政治', '历史', '地理', '生物', '物理'],
        data: xValues.value,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          fontSize: 14,
          color: '#000',
          interval: 0
        }

      }
    ],
    legend: {
      show: true,
      icon: 'circle',
      right: '1%',
    },
    // color: colors,
    yAxis: {
      type: 'value',
      axisLabel: {
        fontSize: 14,
        color: '#000'
      },
      // max: maxYValue.value,
      // min: 0,
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#656565',
      borderColor: "rgba(125,125,125,0.5)",
      textStyle: {
        color: "#fff"
      }
    },
    grid: {
      bottom: '10rem',
      right: '10rem',
      left: '30rem',
      containLabel: true
    },
    // series: series.value
    series: [
      {
        type: 'custom',
        name: '科目最高',
        type: 'bar',
        // barWidth: 22,
        barWidth: '8%',
        barMaxWidth: '10',
        barGap: '-100%',

        label: {
          show: true,
          align: "center",
          color: "#999",
          position: "top",
          opacity: 1
        },

        markPoint: {
          // data: [92, 82, 91, 84, 90, 50, 90, 60, 88],
          // data: [
          //   { coord: [0, 92] },
          //   { coord: [1, 82] },],
          data: topDataListPoints.value,
          symbolSize: 11,
          symbol: "circle",
          itemStyle: {
            borderWidth: 4,
            borderColor: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 40, 0.2)'
          }
        },
        itemStyle: {
          borderRadius: 40,
          color: "#6CB9F5",
          shadowBlur: 2,
          shadowColor: '#6CB9F5',
          opacity: 0.5
        },
        data: dataMax.value

      },
      {
        name: '自身科目',
        type: 'bar',
        barGap: '-100%',
        barWidth: '8%',
        barMaxWidth: '10',

        label: {
          show: true,
          align: "center",
          position: "top",
          color: '#999'
        },
        itemStyle: {
          borderRadius: 40,
          shadowBlur: 2,
          shadowColor: '#72DAE4',
          color: '#72DAE4'
        },
        // xAxisIndex:4,
        // data: [60, 42, 60, 73, 10, 13, 11, 50, 20, 30],
        data: dataSelf.value
      },

    ]
  })
  window.addEventListener('resize', resizeChart)

}
const resizeChart = () => {
  myChart.resize()
}

onUnmounted(() => {
  clear()
  window.removeEventListener('resize', resizeChart)
})
</script>

<style lang='scss' scoped>
.module_name {
  font-size: 24rem;
  padding: 20rem 48rem 0;
}
.no_data {
  text-align: center;
  line-height: 200rem;
  color: #ccc;
}
.mainChart {
  padding: 0 20rem;
  .title {
    text-align: center;
    font-size: 24rem;
    padding: 10rem 15rem 8rem;
  }
}
</style>












